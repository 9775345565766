import {
  abg,
  callZigzagFunction,
  cbk,
  ckla,
  compareWithAppVersion,
  csp,
  gsbh,
  gssl,
  injectZigzagHandler,
  isAndroid,
  isBrowser,
  isDev,
  logAppsFlyer,
  logBraze,
  logFacebook,
  logFirebase,
  SavedProduct,
  SavedProductStatus,
  SaveProduct,
  st,
  sual,
  tt,
  UserAccount,
  zigzagHandler,
} from '@croquiscom-pvt/web2app';

import { alertAppVersionUpdate } from './utils/alertAppVersionUpdate';
import { isMethodNotFoundError } from './utils/isMethodNotFoundError';

// TODO: 나중에 zigzag-www 속 모듈로 합칠 것
export const _zigzagRetentionHandler = (name: string, callback: (arg: any) => void) => {
  if (!isBrowser()) {
    return console.warn(`Should invoke zigzagHandler.${name} in client`);
  }

  const _window: any = window;
  const zigzagHandler = _window.zigzagHandler;
  if (!zigzagHandler && !isDev()) {
    return console.warn('Should call injectZigzagHandler before zigzagHandler');
  }

  zigzagHandler[name] = callback;
};

interface UBL {
  category: string;
  navigation: string;
  navigation_sub?: string;
  object_type?: string;
  object_id?: string | null;
  object_section?: string;
  object_url?: string;
  object_idx?: number;
  data?: string;
}

// UMD 업데이트(rbk, abk, sp, rsp)에 필요한 데이터
export type UserMetaData = { [key: string]: any };

// UMD 업데이트시 적재 되는 UBL 의 "data"
export type UserMetaDataUBLData = { [key: string]: string | number | boolean | null | undefined | UserMetaDataUBLData };
export interface ActionOptionalLog extends Pick<UBL, 'object_section' | 'object_idx'>, UserMetaDataUBLData {
  // passThrough 가 falsy 일 경우 넘어 오는 값을 logs.server_log 에 할당, true 일 경우 logs 로 바로 할당
  passThrough?: boolean;
}

// Note: UBL data 항목에 적재되는 값을 확장 하기 위해 해당 부분 수정 하였습니다
const parseUserMetaData = (metadata: UserMetaData, log?: ActionOptionalLog) => {
  if (!log) {
    return {
      ...metadata,
      logs: isAndroid() ? JSON.stringify({}) : undefined,
    };
  }

  // 기존 parsing 로직과 호환유지를 위해 passThrough 값이 true 일 경우에만 rest 값을 로그로 바로 할당합니다.
  const { passThrough, ...rest } = log;
  const logs = passThrough ? rest : { server_log: rest };

  if (compareWithAppVersion('7.85.0') && typeof logs.data === 'string') {
    logs.data = JSON.parse(logs.data);
  }

  return {
    ...metadata,
    logs: isAndroid() ? JSON.stringify(logs) : logs,
  };
};

const sendUbl = (log: UBL) => {
  /**
   * AOS 7.85.0인 경우 data.data 형태로 data 전송
   * https://croquis.slack.com/archives/C031FJXQNTH/p1706079538880849?thread_ts=1706058581.110729&cid=C031FJXQNTH
   */
  const shouldWrapNestedDataField = isAndroid() && compareWithAppVersion('7.85.0') && !compareWithAppVersion('7.85.1');
  if (log.data && shouldWrapNestedDataField) {
    log.data = { data: log.data } as any;
  }

  if (isAndroid()) {
    callZigzagFunction('subl')({ log: JSON.stringify(log) });
    return;
  }
  callZigzagFunction('subl')({ log });
};

const addBookMark = (shopMainDomain: string, logs?: ActionOptionalLog) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      callZigzagFunction('abk')(parseUserMetaData({ shopMainDomain }, logs));
      zigzagHandler('onBookmarkStatus', (maps) => resolve(maps[shopMainDomain]));
    } catch (e) {
      if (isMethodNotFoundError(e)) {
        return alertAppVersionUpdate();
      }
      reject(e);
    }
  });
};

const removeBookMark = (shopMainDomain: string, logs?: ActionOptionalLog) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      callZigzagFunction('rbk')(parseUserMetaData({ shopMainDomain }, logs));
      zigzagHandler('onBookmarkStatus', (maps) => resolve(maps[shopMainDomain]));
    } catch (e) {
      if (isMethodNotFoundError(e)) {
        return alertAppVersionUpdate();
      }
      reject(e);
    }
  });
};

const saveProduct = (saveProductData: SaveProduct, logs?: ActionOptionalLog) => {
  return new Promise<SavedProductStatus>((resolve, reject) => {
    try {
      callZigzagFunction('sp')(parseUserMetaData(saveProductData, logs));
      zigzagHandler('onSavedProductStatus', (savedProductStatusList) => resolve(savedProductStatusList[0]));
    } catch (e) {
      if (isMethodNotFoundError(e)) {
        return alertAppVersionUpdate();
      }
      reject(e);
    }
  });
};

const removeSavedProduct = (savedProductData: SavedProduct, logs?: ActionOptionalLog) => {
  return new Promise<SavedProductStatus>((resolve, reject) => {
    try {
      callZigzagFunction('rsp')(parseUserMetaData(savedProductData, logs));
      zigzagHandler('onSavedProductStatus', (savedProductStatusList) => resolve(savedProductStatusList[0]));
    } catch (e) {
      if (isMethodNotFoundError(e)) {
        return alertAppVersionUpdate();
      }
      reject(e);
    }
  });
};

const checkUserAccount = () => {
  return new Promise<UserAccount>((resolve) => {
    callZigzagFunction('getUserAccount')();
    zigzagHandler('onUserAccount', (userAccount) => {
      resolve(userAccount);
    });
  });
};

const getOfferwallAvailableReward = (type: string) => {
  return new Promise<number | undefined>((resolve) => {
    if (compareWithAppVersion('8.12.0')) {
      callZigzagFunction('goar')({ type });
    } else {
      callZigzagFunction('goar')();
    }

    _zigzagRetentionHandler('onOfferwallAvailableReward', (reward) => {
      resolve(reward);
    });
  });
};

const checkContactUsage = () => {
  return new Promise<{ granted: boolean } | undefined>((resolve) => {
    callZigzagFunction('checkContactUsage')();
    _zigzagRetentionHandler('onContactUsage', (granted) => {
      resolve(granted);
    });
  });
};

const requestContactList = () => {
  return new Promise<Array<{ name: string; tel: string }> | undefined>((resolve) => {
    callZigzagFunction('requestContactList')();
    _zigzagRetentionHandler('onContactList', (contact) => {
      resolve(contact);
    });
  });
};

const overwriteBack = (callback: (back: () => void) => void) => {
  injectZigzagHandler();
  _zigzagRetentionHandler('handleBackClick', () => {
    callback(callZigzagFunction('back'));
    return true;
  });
};

const setTheme = (theme?: string) =>
  compareWithAppVersion('8.13.0') &&
  callZigzagFunction('setTheme')({ theme: isAndroid() ? JSON.stringify({ theme }) : theme });

// ZigzagEvent
export const getShopProductNo = (shop_main_domain: string, product_no: string) => shop_main_domain + '$' + product_no;

export const ZigzagEvent = {
  sual: (log: string) => sual('action', log),
  /**
   *  @deprecated 삭제 에정. common/log-manager의 useUBL(권장) 또는 common/log-manager의 ZigzagEvent.subl 사용.
   */
  subl: (log: UBL) => sendUbl(log),
  sual_app: (log: string) => sual('app', log),
  sual_info: (log: string) => sual('info', log),
  st,
  tt,
  ckla,
  cbk,
  abk: (shopMainDomain: string, logs?: ActionOptionalLog) => addBookMark(shopMainDomain, logs),
  rbk: (shopMainDomain: string, logs?: ActionOptionalLog) => removeBookMark(shopMainDomain, logs),
  gssl,
  csp,
  sp: (saveProductData: SaveProduct, logs?: ActionOptionalLog) => saveProduct(saveProductData, logs),
  rsp: (savedProductData: SavedProduct, logs?: ActionOptionalLog) => removeSavedProduct(savedProductData, logs),
  abg,
  cua: checkUserAccount,
  gsbh,
  /**
   *  @deprecated 삭제 에정. common/log-manager의 ZigzagEvent.logBraze 사용.
   */
  logBraze: (eventName: string, parameters?: Record<string, unknown>) => {
    if (compareWithAppVersion('6.77.0')) {
      logBraze(eventName, parameters);
    }
  },
  /**
   *  @deprecated 삭제 에정. common/log-manager의 ZigzagEvent.logAppsFlyer 사용.
   */
  logAppsFlyer: (eventName: string, parameters?: Record<string, unknown>) => {
    if (compareWithAppVersion('6.77.0')) {
      logAppsFlyer(eventName, parameters);
    }
  },
  /**
   *  @deprecated 삭제 에정. common/log-manager의 ZigzagEvent.logFacebook 사용.
   */
  logFacebook: (eventName: string, parameters?: Record<string, unknown>) => {
    if (compareWithAppVersion('6.77.0')) {
      logFacebook(eventName, parameters);
    }
  },
  /**
   *  @deprecated 삭제 에정. common/log-manager의 ZigzagEvent.logFirebase 사용.
   */
  logFirebase: (eventName: string, parameters?: Record<string, unknown>) => {
    if (compareWithAppVersion('6.77.0')) {
      logFirebase(eventName, parameters);
    }
  },
  sel: (url: string) => {
    callZigzagFunction('sel')({ url });
  },
  getOfferwallAvailableReward,
  checkContactUsage,
  requestContactList,
  back: callZigzagFunction('back'),
  overwriteBack,
  showPopup: (url: string) => {
    const createParams = (url: string, allowScrollToDismiss: boolean | undefined, versionCheck: boolean) => {
      return {
        url,
        ...(versionCheck && allowScrollToDismiss !== undefined && { allowScrollToDismiss }),
      };
    };

    const versionCheck = compareWithAppVersion('8.4.0');
    const param = createParams(url, false, versionCheck);
    callZigzagFunction('showPopup')(param);
  },
  sse: (isEnabled: boolean) => callZigzagFunction('sse')({ isEnabled }),
  setTheme: (theme: string) => setTheme(theme),
};

/**
 * @description 에어브릿지를 위한 카테고리 포매팅 함수
 */
export function formatCategoryList<Item>(list: Item[] | null | undefined, key: keyof NonNullable<Item>): string {
  if (!list) {
    return '';
  }
  return list.reduce((acc, value) => {
    if (!value) {
      return acc;
    } else {
      return acc + (acc ? ', ' : '') + value[key];
    }
  }, '');
}

import { useEffect } from 'react';

import { getKakaoAPI } from '@common/utils';

/**
 * 카카오 API를 컴포넌트가 마운트되는 시점에 미리 불러옵니다.
 * @param shouldInit 지정된 경우 이 함수가 true를 반환할 때에만 카카오 API를 불러옵니다.
 */
export function useKakaoAPI(shouldInit?: () => boolean) {
  useEffect(() => {
    if (shouldInit != null && !shouldInit()) {
      return;
    }
    getKakaoAPI();
  }, []);
}

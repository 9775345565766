import { createElement } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { getI18n, I18nextProvider, Namespace } from 'react-i18next';

import { I18nValues } from '@common/i18n';

export interface OverlayCompProps<Resolve = string, Reject = unknown> {
  resolve?: (value: Resolve) => void;
  reject?: (error: Reject) => void;
  dispose?: () => void;
  [key: string]: any;
}

export type RenderOverlay<ResolveValue = string, Message = unknown> = (
  message: Message,
  ns?: Namespace,
  values?: I18nValues,
  time?: number,
  style?: React.CSSProperties,
) => (overlayComponentProps: OverlayCompProps<ResolveValue>) => JSX.Element;

export function createOverlayComp<ReturnValue = string, Message = unknown>(
  renderComponent: RenderOverlay<ReturnValue, Message>,
  unmountDelay = 300,
  mountingNode?: HTMLElement,
): (
  props: Message,
  ns?: Namespace,
  values?: I18nValues,
  time?: number,
  style?: React.CSSProperties,
) => Promise<ReturnValue> {
  return (props: Message, ns?: Namespace, values?: I18nValues, time?: number, style?: React.CSSProperties) => {
    const wrapper = (mountingNode || document.body).appendChild(document.createElement('div'));
    const dialog = renderComponent(props, ns, values, time, style);

    function dispose() {
      setTimeout(() => {
        unmountComponentAtNode(wrapper);
        setTimeout(() => {
          if (document.body.contains(wrapper)) {
            document.body.removeChild(wrapper);
          }
        });
      }, unmountDelay);
    }

    const promise = new Promise((resolve: (value: ReturnValue) => void, reject) => {
      const Comp = createElement(I18nextProvider, { i18n: getI18n() }, dialog({ dispose, reject, resolve }));
      try {
        render(Comp, wrapper);
      } catch (e) {
        console.error(e);
        throw e;
      }
    });

    return promise.then(
      (result) => {
        return result;
      },
      (result) => {
        dispose();
        return Promise.reject(result);
      },
    );
  };
}

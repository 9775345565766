import { Gray_Light_200 } from '@common/styles';

export const SliderNavigatorArrowLeftIcon = ({
  width = 16,
  height = 24,
  color = Gray_Light_200,
  ...props
}: JSX.IntrinsicElements['svg']) => (
  <svg width={width} height={height} viewBox='0 0 16 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.83019 11.7075L12.133 19L10.7197 20.4151L2 11.7075L10.7197 3L12.133 4.4151L4.83019 11.7075Z'
      fill={color}
    />
  </svg>
);
